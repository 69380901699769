import React from 'react'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

type TCollapseChildren = {
  description?:Array< string >,
  list?:Array< string >,
  Image:React.ElementType | string,
  sizeIcon?:string
}

const Container = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    display:flex; justify-content:space-between;

    @media ${ device.maxMobileL } {
      justify-content:center; align-items:center;
      flex-direction:column-reverse;
    }
  `
const Block = styled.div`
    border: ${ Grids ? '1px solid darkgreen':'none' };
    margin:15px;

    figure{ width:350px; height:auto; svg,img{ width:100%; height:auto; } }

    @media ${ device.maxMobileL } { figure{ width:315px; } }
  `

const Description = styled.p` font-size:15px; padding:10px 0 5px 10px; `
const List = styled.div`
    padding:10px;

    ul{ padding:0 15px; li{ padding:5px 0; } }
  `

export const ModulesSoftwareCollapse:React.FC< TCollapseChildren > = ( { description, list, Image, sizeIcon  } ) => {
  return(
    <Container>
      <Block>
        <Description style={ { display:description?.length === 0 ? 'none':'block' } }>
          {
            description?.map( ( item:string, index:number ) => <p key={ index }>{ item }</p>) 
          }
        </Description>
        <List>
          <ul>
            { list?.map( ( items:string, index:number ) => <li key={ index }>{ items }</li> ) }
          </ul>
        </List>
      </Block>
      <Block>
        <figure style={ { width: sizeIcon ? sizeIcon : '' } }>
          <Image />
        </figure>
      </Block>
    </Container>
  )
}
import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

export const Container = styled.div`
    border: ${ Grids ? '1px solid green' : 'none' };
    text-align:start;

    h2{ position:relative; top:15px; font-size:42px; color:rgb(239 127 26 / 24%); text-transform:uppercase; }
    h3{ font-size:50px; color:#13087A; position:relative; z-index:1; }

    @media ${ device.maxMobileL } {
      margin:0 10px;
      h2{ font-size:26px; }
      h3{ font-size:35px; }
    }
  `
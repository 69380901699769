import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

export const FlexContainer = styled.div`
    border: ${ Grids ? '1px solid red' : 'none' };
    display:flex; justify-content:center;

    @media ${ device.maxMobileL } {
      flex-direction:column;
      align-items:center;
    }
  `

export const Text = styled.p`
    border: ${ Grids ? '1px solid blue' : 'none' };
    padding:25px 0; line-height:25px;
    
    @media ${ device.maxMobileL } { margin:0 10px; }
  `

export const Icon = styled.div`
    border: ${ Grids ? '1px solid blue' : 'none' };
    svg{ width:100%; height:auto; }
  `

export const Block = styled.div<{ $width?:number, $margin?:string }>`
    border: ${ Grids ? '1px solid orange' : 'none' };
    width: ${ props => props.$width !== undefined ? props.$width+'px' : '100%' };
    height:auto;

    ${ ( props ) => { if( props.$margin ) { return 'margin:' + props.$margin } } };

    @media ${ device.maxMobileL } {
      width:auto; margin:0;
      ${ Icon } { svg{ width:50%; } text-align:center; }
    }
  `
export const arrContacts = [
  { title:'Найдите нас', alt:'IconGeo', description:'г. Барнаул, ул. Ярных 49, офис 305' },
  { title:'Позвоните нам', alt:'IconPhone', description:'+7 (964) 603 20 43' },
  { title:'Напишите нам', alt:'IconEmail', description:'info@sabpek.com' },
]

export const geo = {
  title:'Найдите нас', alt:'IconGeo',
  description:'г. Барнаул, ул. Ярных 49, офис 305'
}

export const phone = {
  title:'Позвоните нам', alt:'IconPhone',
  description:'+7 (964) 603 20 43'
}

export const email = {
  title:'Напишите нам', alt:'IconEmail',
  description:'info@sabpek.com'
}
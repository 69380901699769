import React from 'react'

import { FloatButton } from 'antd'

import { Header } from 'Widgets/Header'
import { Footer } from 'Widgets/Footer'
import { Navigation } from 'Widgets/Navigation'

import { PartnersInfo } from 'Widgets/PartnersGroup/PartnersInfo'

import { CSSWrapper } from 'Shared/Styles/CSSTopWrapper'

import { Data } from './data'

import {
  ReactComponent as IcOperationalManagement
} from 'Shared/Assets/images/OperationalManagement/IcOperationalManagement.svg'

export const PartnersPage:React.FC = ( ) => {
  return(
    <React.Fragment>
      <CSSWrapper.Top>
        <Navigation />
        <Header
            header={ { title:Data.title , paragraph:Data.paragraph, btn:Data.btn } }
            Image={ IcOperationalManagement } documents={ null } sizeIcon={ 650 } />
      </CSSWrapper.Top>

      <CSSWrapper.Body>
        <PartnersInfo />
      </CSSWrapper.Body>

      <CSSWrapper.Footer>
        <FloatButton.BackTop />
        <Footer />
      </CSSWrapper.Footer>
    </React.Fragment>
  )
}
import styled from 'styled-components'

export const PushList = styled.ul`
    list-style:none;

    li{
      position: relative;
      padding: 20px 0 20px 40px;

      &::before{
        content:'';
        position:absolute; left:0; top:27px;
        width:7px; height:7px;
        
        background:#4F5151; border-radius:50%;
        transition:.3s ease-in-out;
      }

      &::after{
        content:'';
        position:absolute; bottom:-12px; left:3px; top:48px;
        border-left:1px dotted #4F5151;
        width:1px;
      }

      &:hover::before{ box-shadow: 0 0 0 10px rgba(0,0,0,.2); }
      &:last-child:after{ content:none; }
    }
  `
import React from 'react'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { Data } from '../Data/data'

import { ReactComponent as IcMinchifra } from 'Shared/Assets/images/sabpek.page/IcMichifra_new.svg'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

const Container = styled.div`
    border: ${ Grids ? '1px solid darkorange':'none' };
    display:flex; justify-content:space-around; align-items:center;
    margin:30px;

    @media ${ device.maxMobileL } { flex-direction:column-reverse; margin:0; }
  `
const Icon = styled.div`
    border: ${ Grids ? '1px solid green':'none' };
    width:500px; height:auto; margin:20px 0 20px 100px;

    svg{ width:100%; height:auto; }

    @media ${ device.maxMobileL } { width:auto; margin:20px 10px; }
  `
const Content = styled.div`
    border: ${ Grids ? '1px solid green':'none' };
    width:500px; height:auto; margin:20px 0;

    p{ line-height:23px; text-align:justify; a{ text-decoration:none; } }

    @media ${ device.maxMobileL } { width:auto; margin:10px 10px; }
  `

export const RegisterDomesticSoftware:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 40 }>
      <PrimaryHeader title={ Data.title } />

      <Container>
        <Content>
          <p>
          САБПЭК внесен в Единый реестр российских программ для ЭВМ и баз 
          данных под <a href={ Data.link }>№17233</a> на основании поручения Министерства цифрового развития, связи 
          и массовых коммуникаций Российской Федерации от 11.04.2023.
          </p>
        </Content>
        <Icon>
          <IcMinchifra />
        </Icon>
      </Container>
    </PrimaryContainer>
  )
}
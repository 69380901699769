import React from 'react'

import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse, type CollapseProps } from 'antd'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { Data } from './data'

import { ReactComponent as IcGeoIS } from 'Shared/Assets/images/main.page/IcGeoIS.svg'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

const Container = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    display:flex; justify-content:center;

    @media ${ device.maxMobileL } {
      flex-direction:column; align-items:center;
      margin:0 10px;
    }
  `
const Content = styled.div`
    border: ${ Grids ? '1px solid darkgreen':'none' };
    width:500px; height:auto;

    p{ padding:10px 0; line-height:25px; text-align:justify; }

    @media ${ device.maxMobileL } { width:auto; }
  `
const Icon = styled.div`
    border: ${ Grids ? '1px solid darkgreen':'none' };
    width:320px; height:auto; margin-right:100px;
    svg{ width:100%; height:auto; }

    @media ${ device.maxMobileL } { width:250px; margin-right:0; }
  `

const items:CollapseProps[ 'items' ] = [
  {
    key:'1', label:Data.titleCollapsed,
    children:
      <ul style={ { marginLeft:20 } }>
        { Data.list.map( ( item:string, index:number ) => <li key={ index }>{ item }</li> ) }
      </ul>
  }
]

export const GeoIS:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 60 }>
      <PrimaryHeader title={ Data.title } />
      <Container>
        <Icon>
          <IcGeoIS />
        </Icon>

        <Content>
          { Data.description.map( ( item:string, index:number ) => <p key={ index }>{ item }</p> ) }

          <Collapse
              expandIcon={ ( { isActive } ) => <CaretRightOutlined rotate={ isActive ? 90 : 0 } /> }
              items={ items }
            />
        </Content>
      </Container>
    </PrimaryContainer>
  )
}
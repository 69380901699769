import React from 'react'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

const Container = styled.div`
    border: ${ Grids ? '1px solid darkorange':'none' };
    display:flex; align-items:start; justify-content:center;
    margin:20px;
  `

export const SolvedBusinessTasks:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 40 }>
      <PrimaryHeader title='Решаемые бизнес-задачи' />
      
      <Container>     
        <iframe
            style={ { margin:20 } }
            width="560" height="315"
            src="https://www.youtube.com/embed/YIS-WTSOde8?si=UhcvwRU4hcP1OueM"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          />
      </Container>
    </PrimaryContainer>
  )
}
import React from 'react'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

const Block = styled.div`
    border: ${ Grids ? '1px solid pink':'none' };
    width:auto; height:auto; margin:20px;
    display:flex;

    @media ${ device.maxMobileL } { width:100%; }
  `
const Number = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    width:35px; height:35px; margin:5px;

    svg{ width:100%; height:auto; }
  `
const Content = styled.div`
    border: ${ Grids ? '1px solid darkorange':'none' };
    width:370px; height:auto; margin:5px;

    h3{ text-transform:uppercase; margin:7px auto; }
    p{ font-size:18px; line-height:24px; margin-top:10px; }
  `

type TProblemSolvingBlock = {
  number:number,
  title:string,
  description:string,
  Icon:React.ElementType | string
}

export const ComprehensiveProblemSolvingBlock:React.FC< TProblemSolvingBlock > = (
  { number, title, description, Icon }
) => {
  return(
    <Block>
      <Number>
        <Icon />
      </Number>
      <Content>
        <h3>{ title }</h3>
        <p>{ description }</p>
      </Content>
    </Block>
  )
}
import React from 'react'

import { BigDoubleHeader } from 'Shared/UI/HeaderGroup/BigDoubleHeader'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { FlexContainer, Block, Text, Icon } from './Styles'

import { Data } from './data'

import { ReactComponent as IcAgile } from 'Shared/Assets/images/main.page/Agile.svg'

export const Agile:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 40 }>   
      <FlexContainer>
        <Block $width={ 500 }>
          <BigDoubleHeader title={ Data.title } subtitle={ Data.subtitle } />
          <Text>{ Data.text }</Text>
        </Block>
        <Block $width={ 340 } $margin='0 0 0 100px'>
          <Icon>
            <IcAgile />
          </Icon>
        </Block>
      </FlexContainer>
    </PrimaryContainer>
  )
}
import React from 'react'

type TImage = { Icon:React.ElementType | string }

export const Image:React.FC< TImage > = ( { Icon } ) => {
  return(
    <>
      { typeof Icon == 'string' ? <img src={ Icon } alt='icon' /> : <Icon /> }
    </>
  )
}
import React from "react";
import { RootState } from "App/Configure/store";
import { useSelector, useDispatch } from "react-redux";
import { changeOpenDrMobileMenu } from "App/Configure/BasicSettingsSlice";
import { Drawer } from "antd";
import { Menus } from "Shared/UI/Menu";
import { Container, Text, Icon } from "./Styles";
import { phone, geo, email } from "Shared/consts/contacts";

import ITLogo from "Shared/Assets/images/IT_Logo_black.svg";

export const DrMobileMenu: React.FC = () => {
  const openDrMobileMenu = useSelector(
    (props: RootState) => props.BasicSlice.openDrMobileMenu,
  );

  const dispatch = useDispatch();

  return (
    <Drawer
      open={openDrMobileMenu}
      onClose={() => dispatch(changeOpenDrMobileMenu())}
      styles={{ body: { padding: 35 } }}
      placement="left"
      footer={
        <Container>
          <Icon>
            <img
              src={ITLogo}
              alt={"ITLogo"}
              style={{ width: "180px", height: "auto", marginBottom: "10px" }}
            />
          </Icon>
          <Text>
            <p>
              Тел.: <a href={"tel:" + phone.description}>{phone.description}</a>
            </p>
            <p>
              Эл.почта:{" "}
              <a href={"mailto:" + email.description}>{email.description}</a>
            </p>
            <p>Адрес: {geo.description}</p>
          </Text>
        </Container>
      }
    >
      <Menus type="mobile" />
    </Drawer>
  );
};

import React from 'react'

import { Footer } from 'Widgets/Footer'
import { Navigation } from 'Widgets/Navigation'
import { Error } from 'Widgets/Error'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { CSSWrapper } from 'Shared/Styles/CSSTopWrapper'

export const ErrorPage:React.FC = ( ) => {
  return(
    <React.Fragment>
      <CSSWrapper.Top>
        <PrimaryContainer $margin={ 0 }>
          <Navigation />
        </PrimaryContainer>
      </CSSWrapper.Top>

      <CSSWrapper.Body>
        <PrimaryContainer $margin={ 40 }>
          <Error />
        </PrimaryContainer>
      </CSSWrapper.Body>

      <CSSWrapper.Footer>
        <Footer />
      </CSSWrapper.Footer>
    </React.Fragment>
  )
}
import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

export const CopyrightContainer = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
    display:flex; justify-content:space-between; align-items:start;
    width:100%;
  `

export const DateCopyright = styled.div`
    border: ${ Grids ? '1px solid darkgreen' : 'none' };

    p{ font-size:12px; color:#828282; padding-top:2px; padding-bottom:2px; }
  `

export const PDP = styled.div`
    border: ${ Grids ? '1px solid darkgreen' : 'none' };
    margin-right:55px;

    a{
      font-size:12px; color:#828282; text-decoration:none;
      transition:.4s;

      &:hover{ color:#FFFFFF; }
    }

    @media ${ device.maxMobileL } { margin-right:0; }
  `
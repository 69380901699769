import React from 'react'

import { InputWithLoad } from 'Shared/UI/InputGroup/InputWithLoad'

import {
  MiniFormContainer,
  BlockDescription,
  BlockMiniFeedback } from './Styles'

import { TMiniForm } from './Type'

export const MiniForm:React.FC< TMiniForm > = ( { data } ) => {
  return(
    <MiniFormContainer>
      <BlockDescription>
        <h2>{ data.title }</h2>
        <p>{ data.text }</p>
      </BlockDescription>
      <BlockMiniFeedback>
        <InputWithLoad />
      </BlockMiniFeedback>
    </MiniFormContainer>
  )
}
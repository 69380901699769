import React from "react";
import { Link } from "react-router-dom";
import { RootState } from "App/Configure/store";
import { useSelector, useDispatch } from "react-redux";
import { changeOpenDrMobileMenu } from "App/Configure/BasicSettingsSlice";
import { Menus } from "Shared/UI/Menu";
import { BtnMobileMenu } from "Shared/UI/BtnGroup/BtnMobileMenu";
import { DrMobileMenu } from "Shared/Components/Drawer.group/MobileMenu";
import { size } from "Shared/consts/MediaQueries";
import { LayerNavigation, Box } from "./Styles";

import ITLogo from "Shared/Assets/images/IT_Logo_white.svg";

export const Navigation: React.FC = () => {
  const clientWidth = window.innerWidth;

  const openDrMobileMenu = useSelector(
    (props: RootState) => props.BasicSlice.openDrMobileMenu,
  );
  const dispatch = useDispatch();

  return (
    <LayerNavigation $clWidth={clientWidth}>
      <Box>
        <figure>
          <Link to="/">
            <img
              src={ITLogo}
              alt={"ITLogo"}
              style={{ width: "200px", height: "auto" }}
            />
          </Link>
        </figure>
      </Box>

      <Box width="100%">
        {clientWidth >= size.tablet ? (
          <Menus type="desctop" />
        ) : (
          <>
            <BtnMobileMenu
              status={openDrMobileMenu}
              fCallback={changeOpenDrMobileMenu}
              fDispatch={dispatch}
            />
            <DrMobileMenu />
          </>
        )}
      </Box>
    </LayerNavigation>
  );
};

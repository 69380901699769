export interface IList {
  title:string, description:string,
}

export const Data = {
  title:'Эффект от внедрения',
  list:[
    { title:'на 213-380%', description:'увеличение эффективности контроллеров' },
    { title:'на 15-40%', description:'уменьшение нагрузки на операторов' },
    { title:'32 млн.руб. экономия', description:'за 1 год оптимизации передвижения контроллеров' },
  ],
  ullist:[
    'За счет контроля при выполнении обходов и ремонтов энергооборудования',
    'Изменения мотивации персонала на основе реальной статистики по трудозатратам',
    'Сокращения внеплановых ремонтов и сверхурочной работы',
  ]
}
import React from 'react'

import { BigDoubleHeader } from 'Shared/UI/HeaderGroup/BigDoubleHeader'
import { SequenceBoxIconDesc } from 'Shared/Components/SequenceGroup/BoxIconDesc'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'

import { Data } from './data'

import { ReactComponent as AP } from 'Shared/Assets/images/main.page/services/AerialPhotography.svg'
import { ReactComponent as ABP } from 'Shared/Assets/images/main.page/services/AutomationBusinessProcesses.svg'
import { ReactComponent as CNN } from 'Shared/Assets/images/main.page/services/CreatingNeuralNetworks.svg'
import { ReactComponent as MM } from 'Shared/Assets/images/main.page/services/ModernizationMaintenance.svg'
import { ReactComponent as SD } from 'Shared/Assets/images/main.page/services/SoftwareDevelopment.svg'
import { ReactComponent as ST } from 'Shared/Assets/images/main.page/services/StaffTraining.svg'

const iconSVG = ( value:string ) => {
  switch( value ) {
    case 'AP':return AP
    case 'ABP':return ABP
    case 'CNN':return CNN
    case 'MM':return MM
    case 'SD':return SD
    case 'ST':return ST
    default:return 'Error. Icon don\'t found.'
  }
}

export const Services:React.FC = ( ) => {
  return(
    <PrimaryContainer id='services' $margin={ 40 }>
      <BigDoubleHeader title={ Data.title } subtitle={ Data.subtitle } />
      <SequenceBoxIconDesc data={ Data.blocks } fIcon={ iconSVG } />
    </PrimaryContainer>
  )
}
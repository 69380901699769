import React from 'react'

import { useDispatch } from 'react-redux'
import { changeOpenDrawerFeedback } from 'App/Configure/BasicSettingsSlice'

import { BtnPrimary } from 'Shared/UI/BtnGroup/BtnPrimary'
import { DrFeedback } from 'Shared/Components/Drawer.group/Feedback'
import { SequenceBtnDDoc } from 'Shared/Components/SequenceGroup/BtnDDoc'

import { HeaderContainer, Block, Title, Paragraph, FuncLayer, ImgHeader } from './Styles'
import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'

import { THeaderDefault } from './Types'

export const Header:React.FC<THeaderDefault> = ( { header, Image, documents, sizeIcon } ) => {

  const heightClient = document.documentElement.clientHeight

  const dispatch = useDispatch()

  return(
    <PrimaryContainer>
      <HeaderContainer $height={ heightClient -58 }>
        <Block $width={ 580 }>
          <Title>{ header.title }</Title>
          <Paragraph className='_anim-items'>
            { header.paragraph.map( ( item:string, index:number ) => <p key={ index }>{ item }</p> ) }
          </Paragraph>
          <FuncLayer>
            <FuncLayer className='documents' style={ { display:documents == null ? 'none' : 'block' } }>
              { documents ? <SequenceBtnDDoc data={ documents } /> : <></> }
            </FuncLayer>        

            <FuncLayer className='drawer-btn' style={ { display:header.btn ? 'block':'none' } }>
              <BtnPrimary
                  text={ header.btn ? header.btn:'' }
                  fOnClick={ () => dispatch( changeOpenDrawerFeedback() ) }
                />
              <DrFeedback />
            </FuncLayer>
          </FuncLayer>
        </Block>

        <Block>
          <ImgHeader $widthStImg={ sizeIcon ? sizeIcon : null }>
            { typeof Image == 'string' ? <img src={ Image } alt='icon_header' /> : <Image /> }
          </ImgHeader>
        </Block>
      </HeaderContainer>
    </PrimaryContainer>
  )
}

/*
            
*/
import React from 'react'

import { SequenceBoxIconDesc } from 'Shared/Components/SequenceGroup/BoxIconDesc'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { Wrapper, Content } from './Styles'

import { Data } from './data'

import { ReactComponent as OImg } from 'Shared/Assets/images/dias.page/why.dias/1.svg'
import { ReactComponent as TImg } from 'Shared/Assets/images/dias.page/why.dias/2.svg'
import { ReactComponent as ThImg } from 'Shared/Assets/images/dias.page/why.dias/3.svg'
import { ReactComponent as FoImg } from 'Shared/Assets/images/dias.page/why.dias/4.svg'
import { ReactComponent as FiImg } from 'Shared/Assets/images/dias.page/why.dias/5.svg'
import { ReactComponent as SImg } from 'Shared/Assets/images/dias.page/why.dias/6.svg'

const imgSVG = ( name:number ) => {
  switch( name ) {
    case 1:return OImg
    case 2:return TImg
    case 3:return ThImg
    case 4:return FoImg
    case 5:return FiImg
    case 6:return SImg
    default:return 'Error'
  }
}

export const WhyDias:React.FC = ( ) => {
  return(
    <Wrapper>
      <PrimaryContainer $margin={ 60 }>
        <PrimaryHeader title={ Data.title} color='#FFFFFF' />
        <Content>
          <SequenceBoxIconDesc data={ Data.list } fIcon={ imgSVG } />
        </Content>
      </PrimaryContainer>
    </Wrapper>
  )
}

// <ListIconAndDesc data={ Data.list } fIcon={ imgSVG } />
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { AppRouter } from './AppRouter'
import { store } from './Configure/store'

import { ScrollToTop } from 'Shared/Utils/scrollToTop'

import { CSSNormalize } from './Styles/normalize'
import './Styles/antd.css'

export const App:React.FC = ( ) => {
  return(
    <React.Fragment>
    
      <Provider store={ store }>

        <BrowserRouter>
          <ScrollToTop />
          <CSSNormalize />
          <AppRouter />          
        </BrowserRouter>

      </Provider>
    
    </React.Fragment>
  )
}


import React from 'react'

import { Container } from './Styles'

type TBigDoubleHeader = {
  title:string,
  subtitle:string
}

export const BigDoubleHeader:React.FC< TBigDoubleHeader > = ( { title, subtitle } ) => {
  return(
    <Container>
      <h2>{ title }</h2>
      <h3>{ subtitle }</h3>
    </Container>
  )
}
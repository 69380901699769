import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './App/App'
import { ROOT_ID } from 'Shared/consts/ConstantsCommon'


const root = ReactDOM.createRoot(
  document.getElementById( ROOT_ID ) as HTMLElement
)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
import React, { type CSSProperties } from 'react'

import { CaretRightOutlined } from '@ant-design/icons'
import {
  Collapse, theme,
  type CollapseProps
} from 'antd'

import styled from 'styled-components'
const Paragraph = styled.p` font-size:14px; line-height:24px; padding:5px; `

const DataCollapsed:(panelStyle:CSSProperties) => CollapseProps[ 'items' ] = ( panelStyle ) => [
  {
    key:'1', style:panelStyle,
    label:'АВИТИСТ-ТЕХНОПЛЮС',
    children:<>
      <Paragraph><a href='https://avitist.by/'>АВИТИСТ-ТЕХНОПЛЮС</a> — белорусский специализированный интегратор высокотехнологичных и новейших решений в области автоматизированных, информационных, визуальных и инженерных систем, а также систем безопасности и управления.</Paragraph>
      <Paragraph>Инжиниринговая компания-интегратор предлагает широкий спектр технических и программных продуктов, которые помогают энергетическим, нефтеперерабатывающим, горнодобывающим и промышленным организациям существенно повысить свою производительность, эффективно модернизировать индустриальную среду и инфраструктуру.</Paragraph>
    </>,
  },
  {
    key:'2', style:panelStyle,
    label:'АЛМИ Партнер',
    children:<>
      <Paragraph><a href='https://almi-partner.ru/'>АЛМИ Партнер</a> — ведущий отечественный разработчик общесистемного и прикладного программного обеспечения, отличающегося безопасностью и квалифицированной поддержкой.</Paragraph>
      <Paragraph>Группа компаний АЛМИ находится на рынке информационных технологий уже более 20 лет и оказывает полный спектр услуг в ИТ-секторе, который постоянно расширяется за счет освоения передовых востребованных технологий.</Paragraph>
      <Paragraph>Клиентами являются крупнейшие федеральные и региональные компании, крупные торговые, транспортные, сырьевые и промышленные предприятия, министерства, государственные и муниципальные органы, общественные организации.</Paragraph>
    </>,
  },
  {
    key:'3', style:panelStyle,
    label:'Астра',
    children:<>
      <Paragraph><a href='https://astragroup.ru/'>Группа компаний «Астра»</a> — один из лидеров российского рынка информационных технологий в области разработки программного обеспечения (ПО) и средств защиты информации (СЗИ).</Paragraph>
      <Paragraph>Основана в 2008 году. В команде сегодня трудится более 1 000 высокопрофессиональных разработчиков и сотрудников техподдержки, а программные продукты используются в государственных и коммерческих организациях со штатом от 5 до 50 000 человек.</Paragraph>
      <Paragraph>ГК «Астра» формирует стек системного и прикладного ПО, а также сервисы, которые комплексно закроют потребности заказчиков при создании и проектировании инфраструктур.</Paragraph>
    </>,
  },
  {
    key:'4', style:panelStyle,
    label:'Код Безопасности',
    children:<>
      <Paragraph><a href='https://www.securitycode.ru/'>Код Безопасности</a> — российский разработчик программных и аппаратных средств, обеспечивающих защиту информационных систем, а также их соответствие требованиям международных и отраслевых стандартов.</Paragraph>
      <Paragraph>Компания основана в 2008 году и ведет свою деятельность на основании 9 лицензий ФСТЭК России, ФСБ России и Министерства обороны РФ. Более 70 действующих сертификатов, выданных этими органами, подтверждают высокое качество продуктов и позволяют использовать их в информационных системах с самыми жесткими требованиями к безопасности. Продукты компании применяются для защиты конфиденциальной информации, коммерческой тайны, персональных данных и сведений, составляющих государственную тайну. </Paragraph>
    </>,
  },
  {
    key:'5', style:panelStyle,
    label:'Парус электро',
    children:<>
      <Paragraph><a href='https://parus-electro.ru/'>Парус электро</a> — отечественный разработчик и производитель источников бесперебойного питания (ИБП) под брендом «Связь инжиниринг», зарядных станций электромобилей (ЭЗС) и преобразователей для ВИЭ.</Paragraph>
      <Paragraph>В продуктовом портфеле компании ИБП переменного и постоянного тока, аккумуляторные батареи, телекоммуникационные шкафы, энергосберегающее светодиодное освещение.</Paragraph>
      <Paragraph>«Парус электро» выполняет весь комплекс работ, включая разработку, производство, поставку, пусконаладку и сервисное обслуживание решений для систем электропитания инфраструктурного оборудования.</Paragraph>
    </>,
  },
  {
    key:'6', style:panelStyle,
    label:'Ростелеком',
    children:<>
      <Paragraph><a href='https://www.company.rt.ru/'>Ростелеком</a> — крупнейший в России интегрированный провайдер цифровых услуг и решений, который присутствует во всех сегментах рынка и охватывает миллионы домохозяйств, государственных и частных организаций.</Paragraph>
      <Paragraph>Признанный технологический лидер в инновационных решениях в области цифровых государственных сервисов, кибербезопасности, цифровизации регионов, здравоохранения, биометрии, образования, ЖКХ, а также в сфере облачных вычислений и услуг дата-центров и других. Компания последовательно занимается импортозамещением, включая собственную разработку программного обеспечения и производство телеком-оборудования.</Paragraph>
    </>,
  },
  {
    key:'7', style:panelStyle,
    label:'Эффорт Групп',
    children:<>
      <Paragraph><a href='https://effort-group.ru/'>«Эффорт Групп»</a> — крупный дистрибьютор как проектного, так и канального оборудования ведущих производителей цифровых и аналоговых видеокамер, систем контроля и управления доступом, видеорегистраторов и необходимых аксессуаров для монтажа и установки камер, датчиков и систем электропитания.</Paragraph>
      <Paragraph>Входит в ТОП-3 крупнейших дистрибьюторов Hikvision в РФ.</Paragraph>
      <Paragraph>«Эффорт Групп» сегодня — это 4 офиса на территории России, более 200 производителей в продуктовом портфеле, более 100 сотрудников со средним релевантным опытом работы 8 лет и сотни реализованных проектов.</Paragraph>
    </>,
  },
  {
    key:'8', style:panelStyle,
    label:'DBA (DIGITAL BUSINESS ADAPTATION)',
    children:<>
      <Paragraph><a href='https://dba.ooo/'>DBA (DIGITAL BUSINESS ADAPTATION)</a> — системный интегратор и разработчик мультифункциональных программных решений с использованием сквозных цифровых технологий для автоматизации сложных и нелинейных бизнес-процессов.</Paragraph>
      <Paragraph>Компания оказывает услуги анализа и автоматизации бизнес-процессов, администрирования систем, всех видов тестирования, технической поддержки, подготовки технической документации и консалтинга, имеет пул готовых решений, прошедших этап опытной эксплуатации и доказавших свою эффективность в реальных условиях. В числе первых в Алтайском крае начала использовать БПЛА для проведения обследований промышленных объектов, находящихся в труднодоступных местах.</Paragraph>
    </>,
  },
  {
    key:'9', style:panelStyle,
    label:'DEPO Computers',
    children:<>
      <Paragraph><a href='https://www.depo.ru/'>DEPO Computers</a> — российская IT-компания, cистемообразующее предприятие отечественной радиоэлектронной промышленности. Деятельность DEPO Computers имеет стратегическое значение для обеспечения информационной безопасности государства, сохранения и модернизации инфраструктуры, развития российских высоких технологий.</Paragraph>
      <Paragraph>Компания совмещает экспертизу системного интегратора в области инженерной инфраструктуры, виртуализации и информационной безопасности с собственным масштабным производством вычислительной техники под брендом DEPO.</Paragraph>
      <Paragraph>Вычислительная техника DEPO внесена в Реестр промышленной продукции, произведенной на территории Российской Федерации.</Paragraph>
    </>,
  },  
  {
    key:'10', style:panelStyle,
    label:'Directum',
    children:<>
      <Paragraph><a href='https://www.directum.ru/'>Directum</a> — российская ИТ-компания, которая разрабатывает программные продукты и сервисы для управления цифровыми процессами и документами, оказывает полный спектр услуг по выстраиванию цифрового взаимодействия: анализирует бизнес-процессы, создает, внедряет и сопровождает ИТ-решения для организаций различных масштабов и отраслей.</Paragraph>
      <Paragraph>Directum входит в топ-10 вендоров корпоративного ПО в России и СНГ по числу проектов внедрений. Программные продукты компании включены в Единый реестр отечественного ПО, совместимы с российским и свободно распространяемым ПО, соответствуют всем критериям импортонезависимости и устойчивости к внешним санкциям. При облачной поставке ИТ-решения размещаются в ЦОДах на территории РФ. В активе компании десятки проектов внедрения ИТ-решений на платформах OC Linux, СУБД PostgreSQL, Postgres Pro.</Paragraph>
    </>,
  },  
  {
    key:'11', style:panelStyle,
    label:'Fplus',
    children:<>
      <Paragraph><a href='https://fplustech.ru/'>Fplus</a> — российский производитель инфраструктурного оборудования. Входит в ИТ-холдинг Fplus.</Paragraph>
      <Paragraph>Реализуя стратегию импортонезависимости, компания выпускает продукцию по нескольким направлениям. Серверное и сетевое оборудование корпоративного уровня включает линейки серверов, систем хранения данных, коммутаторов.</Paragraph>
      <Paragraph>Оборудование отвечает потребностям заказчиков для построения ИТ-инфраструктуры компаний различного уровня – SMB, Enterprise и любого масштаба – от рабочей группы до уровня крупного ЦОД. Решения Fplus позволяют эффективно решать задачи во многих отраслях: государственном секторе, промышленности, энергетике, образовании, здравоохранении, коммерческих структурах. </Paragraph>
    </>,
  },  
  {
    key:'12', style:panelStyle,
    label:'MONT',
    children:<>
      <Paragraph><a href='https://www.mont.ru/ru-ru'>MONT</a> — один из крупнейших дистрибьюторов в России программного обеспечения для бизнеса любого масштаба.</Paragraph>
      <Paragraph>Группа компаний MONT начала свою деятельность в 1991 году и уже 30 лет занимается облачной, электронной и классической дистрибуцией, технической и сервисной поддержкой проектов. Дистрибьютор представлен во всех федеральных округах России, а также в других странах СНГ, Грузии и Монголии.</Paragraph>
      <Paragraph>Продуктовый портфель включает более 350 иностранных и российских поставщиков по направлениям: облачные сервисы, безопасность, виртуализация, САПР и графика. Специалисты MONT оказывают профессиональную поддержку по самым разным вопросам: от подбора технического решения, расчета спецификации и правил лицензирования ПО до правовой поддержки.</Paragraph>
    </>,
  },
  {
    key:'13', style:panelStyle,
    label:'OCS Distribution',
    children:<>
      <Paragraph><a href='https://www.ocs.ru/'>OCS Distribution</a> — основана в 1994 г. Направление деятельности — дистрибуция компьютерной техники, телекоммуникационного, периферийного, пассивного и сетевого оборудования, компонентов, систем хранения данных, инфраструктурного программного обеспечения, расходных материалов, аксессуаров, бытовой техники и электроники, гаджетов.</Paragraph>
      <Paragraph>В настоящий момент компания OCS Distribution имеет самую широкую среди российских дистрибуторов региональную сеть офисов в 26 городах России.</Paragraph>
      <Paragraph>OCS фокусируется на проектной и вольюмной дистрибуции: работе с системными интеграторами, поставщиками корпоративного рынка, розничными сетями, представителями российской сборки, разработчиками программного обеспечения, универсальными реселлерами и др.</Paragraph>
    </>,
  },
]

export const PartnersCollapse:React.FC = ( ) => {

  const { token } = theme.useToken()

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  }

  return(
    <Collapse
        bordered={ false }
        defaultActiveKey={ ['1'] }
        expandIcon={ ( { isActive } ) => <CaretRightOutlined rotate={ isActive ? 90 : 0 } />}
        style={ { background:token.colorBgContainer } }
        items={ DataCollapsed( panelStyle ) }
      />
  )
}
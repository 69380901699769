export const Data = [
  {
    title:'Обслуживание и ремонт энергооборудования',
    description:[
      'Цифровизация бизнес-процессов, связанных с ремонтом и обслуживанием энергетического оборудования, управление оперативно-диспетчерской службой, оптимизация работ линейного и ИТР персонала по обслуживанию энергообъектов при помощи мобильных устройств.'
    ],
    list:[
      'Инвентаризация сетевой инфраструктуры',
      'Паспортизация энергообъектов',
      'Дефектоскопия оборудования',
      'Формирование и выполнение ремонтной программы',
      'Оперативное управление',
      'Энергетическое обследование',
      'Учет и расследование технологических нарушений',
    ]
  },
  {
    title:'Технологическое присоединение',
    description:[
      'Систематизация бизнес-процесса присоединения / подключения объектов к технологическим сетям электроснабжения для минимизации управленческих затрат и повышения клиентоориентированности энергокомпаний.'
    ],
    list:[
      'Учет и контроль заявок по технологическому присоединению',
      'Автоматизация расчета платы за технологическое присоединение',
      'Подготовка технических условий, акта разграничения балансовой принадлежности, извещения о выполнении технологического присоединения',
      'Подготовка договора технологического присоединения и контроля платы за технологическое присоединение',
      'Контроль выполнение работ (хоз. способом, подрядными организациями)',
      'Контроль и планирование материалов',
      'Система SMS-оповещений заявителей',
      'Интеграция с внешними информационными системами: 1С, Личный кабинет заявителя',
    ]
  },
  {
    title:'Коммерческий учет',
    description:[
      'Система для автоматизации бизнес-процессов по транспорту и распределению электроэнергии.'
    ],
    list:[
      'Сегментирование потребителей электроэнергии',
      'Определение участков сети с высокими показателями по потерям',
      'Отслеживание нерасчетных приборов учета',
    ]
  },
  {
    title:'Оперативное управление',
    description:[],
    list:[
      'Учет оперативных заявок на отключение энергообъектов и технологических нарушений',
      'Повышение эффективности учета технологических нарушений',
      'Оперативность ввода данных и принятия решений',
      'Стандартизация классификаций технологических нарушений',
      'Расследование нарушения, контроль за противоаварийными мероприятиями',
      'Достоверность и обоснованность данных за счет документов и фотоснимков',
      'Сокращение времени на устранение нарушения',
      'Автоматизация уведомлений абонентов об отключении электроэнергии (Email и SMS-оповещения)',
      'Интеграция с сервисом Министерства энергетики РФ для предоставления информации о текущем количестве абонентов',
      'Отправка информации о технологическом нарушении в Министерство энергетики РФ по нажатию кнопки',
    ]
  },
  {
    title:'Балансы электроэнергии ( Технический учет )',
    description:[],
    list:[
      'Построение и настройка схемы сети',
      'Инвентаризация и эксплуатация приборов учета, трансформаторов, напряжения',
      'Формирование балансов электроэнергии на уровне линии, ПС, ТСО за промежуток времени в разрезе уровней напряжения',
      'Определение технико-экономических показателей передачи и распределения электроэнергии',
      'Расчет допустимых и фактических небалансов и перетока электроэнергии по фидерам 6 (10) – 220 кВ',
      'Учет границ балансовой принадлежности для энергообъектов',
      'Выявление участков с максимальной долей коммерческих и технических потерь',
      'Учет переключений с возможностью занесения показаний на момент отключения',
      'Цветовая дифференциация по критическому отклонению от норматива потерь',
    ]
  },
  {
    title:'Автотранспорт',
    description:[],
    list:[
      'Снижение себестоимости транспортных работ',
      'Сокращение потерь от простоев транспорта',
      'Контроль уровня затрат на топливо, ГСМ, шины и прочее',
      'Выявление случаев нецелевого использования транспортных средств, топлива, ГСМ',
      'Своевременное уведомление сотрудников до возникновения событий и при их совершении',
      'Централизованное ведение путевых и ТО',
      'Автопланирование ТО в зависимости от ежедневных пробегов транспортных средств',
    ]
  },
  {
    title:'Мобильные решения',
    description:[
      'Ряд решений для мобильных устройств, автоматизирующих работу контролеров, мастеров, оперативно-выездных бригад в «полях» для повышения эффективности внесения первичных и оперативных данных в информационную систему.',
      'Обеспечивают учет потребления электроэнергии, контроль деятельности персонала и создание единого информационного пространства внутри компании. Решения предназначены для современных энергосетевых и энергосбытовых компаний.',
    ],      
    list:[
      'Цифровизация работы контролеров, мастеров, оперативно-выездных бригад в «полях»',
      'Сбор данных и оперативное их внесение в систему',
      'Создание единого информационного пространства',
      'Нормирование трудозатрат на основе сбора статистики по длительности работ',
      'Оптимизация логистики перемещения и визуализация треков перемещения',
      'Достоверизация данных',
      'Автоматическая фиксация времени исполнения заданий',
      'Эффективное распределение ресурсов',
      'Доступность данных для анализа и аудита действий персонала',
      'Использование менее квалифицированного персонала за счет интерфейса «одной кнопки»',
    ]
  },
]
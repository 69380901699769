export const DataIndexPage = {
  header:{
    title:'ИТ Сфера',
    paragraph:[
      'Разработчик корпоративных биллинговых и ERP-систем для цифровизации бизнес-процессов с применением нейросетевых технологий.',
      'С 2017 г. компания успешно работает на рынке информационных систем для крупных и средних предприятий России и СНГ.'
    ],
    btn:'Оставить заявку'
  },
  services:{ // ! Перенесено в Widgets/IndexGroup/Services/UI/data.ts
    title:'ИТ Сфера', subtitle:'Услуги',
    blocks:[
      { title:"Модернизация и сопровождение", description:"Модернизация и сопровождение сторонних информационных систем", reduction:"MM" },
      { title:"Создание нейросетей", description:"Создание и разработка нейросетей", reduction:"CNN" },
      { title:"Автоматизация бизнес-процессов", description:"Автоматизация бизнес-процессов и бизнес-анализа", reduction:"ABP"},
      { title:"Аэросъемка", description:"Аэросъемка с квадрокоптера в труднодоступных местах", reduction:"AP" },
      { title:"Разработка ПО", description:"Разработка мобильных, WEB, Desktop, AV/VR/MR приложений", reduction:"SD" },
      { title:"Обучение персонала", description:"Обучение персонала заказчика", reduction:"ST" }
    ]
  },
  agile:{ // ! Перенесено в Widgets/IndexGroup/Agile/UI/data.ts
    title:'Используется', subtitle:'Agile-методика',
    paragraph:'При разработке ПО компания использует Agile-методику управления проектами, ключевую роль в которой играют непрерывные релизы и получение обратной связи от заказчиков при каждой итерации'
  }
}
import React from 'react'

import { CheckOutlined } from '@ant-design/icons'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

const Block = styled.div`    
    border:3px solid #EF7F1A; border-radius:5px;
    width:370px; height:125px; margin:30px;
    display:flex; justify-content:flex-start;
    position:relative;

    @media ${ device.maxMobileL } { margin:10px 0; height:auto; }
  `
const Icon = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    background:#EF7F1A;
    border-radius:50%;

    width:40px; height:40px;
    display:flex; align-items:center; justify-content:center;
    position:absolute; left:-20px; top:40px;
  `
const Content = styled.div`
    border: ${ Grids ? '1px solid orange':'none' };
    margin:20px 40px;
    h3{ padding:5px 0; font-size:25px; }
    p{ font-size:18px; }
  `

type TIntriductionBlock = { title:string, text:string }

export const EffectsIntroductionBlock:React.FC< TIntriductionBlock > = ( { title, text } ) => {
  return(
    <Block>
      <Icon><CheckOutlined style={ { fontSize:25, color:'#FFFFFF' } } /></Icon>
      <Content>
        <h3>{ title }</h3>
        <p>{ text }</p>
      </Content>
    </Block>
  )
}
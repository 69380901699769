import React from 'react'

import { RootState } from 'App/Configure/store'
import { useSelector, useDispatch } from 'react-redux'
import {
  changeStateBtnSendMessege,
  changeLoadingBtnSendMessage,
  changeStatusSendMessage,
} from 'App/Configure/BasicSettingsSlice'

import { Form, Input, Checkbox } from 'antd'

import { Link } from 'react-router-dom'

import emailjs from '@emailjs/browser'

import { TFullForm } from './Types'

import { Container, Row, Col, Title, WorkPlace } from './Styles'

export const FullForm:React.FC = ( ) => {

  const SERVICE_ID = useSelector( (state:RootState) => state.BasicSlice.EMAILJS_SERVICE_ID )
  const TEMPLATE_ID = useSelector( (state:RootState) => state.BasicSlice.EMAILJS_TEMPLATE_ID )
  const PUBLIC_KEY = useSelector( (state:RootState) => state.BasicSlice.EMAILJS_PUBLIC_KEY )

  const [ form ] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = (values:TFullForm) => {    
    dispatch( changeLoadingBtnSendMessage() )
    dispatch( changeStatusSendMessage( 'sending' ) )

    emailjs.send( SERVICE_ID, TEMPLATE_ID, values, PUBLIC_KEY )
      .then( () => {
        dispatch( changeLoadingBtnSendMessage() )
        dispatch( changeStatusSendMessage( 'sent' ) )

        /** Очистка формы после отправки сообщения */
        form.resetFields()
        /** Изменения состояния checkbox */
        dispatch( changeStateBtnSendMessege() )
      }, () => {
        dispatch( changeStatusSendMessage( 'error' ) )
      } )    
  }

  return(
    <Container>
      <Title>
        <h2>Обсудите возможности партнерства с компанией</h2>
        <h3>Оставьте заявку или позвоните нам для обсуждения условий.</h3>
      </Title>

      <WorkPlace>
        <Form id='full-form' form={ form } layout='vertical' name='New task' onFinish={ onFinish } scrollToFirstError
              initialValues={ {
                'nickname':'', 'company':'', 'email':'', 'mobPhone':'', 'webSite':'', 'region':'', 'description':''
              } }>
          <Row>
            <Col $width={ 300 }>
              <Form.Item name='nickname' label='Имя' hasFeedback
                    rules={ [ { required:true, message:'Пожалуйста, укажите Ваши инициалы.', whitespace:true } ] }>
                <Input placeholder='Укажите Ваши инициалы.' />
              </Form.Item>
            </Col>
            <Col $width={ 300 }>
              <Form.Item name='company' label='Компания' hasFeedback
                    rules={ [ { required:true, message:'Пожалуйста, укажите наименование Вашей организации', whitespace:true } ] }>
                <Input placeholder='Укажите наименование организации' />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col $width={ 300 }>
              <Form.Item name='email' label='Email' hasFeedback
                    rules={ [
                      { type:'email', message:'Введенный адрес электронной почты неверен!' },
                      { required:true, message:'Пожалуйста, введите свой адрес электронной почты!' }
                    ] }>
                <Input placeholder='Введите адрес электронной почты' />
              </Form.Item>
            </Col>
            <Col $width={ 300 }>
              <Form.Item name='mobPhone' label='Телефон' hasFeedback
                    rules={ [
                      {
                        required:true, pattern:new RegExp(/^[0-9]+$/),
                        message:'Пожалуйста, укажите номер мобильного телефона.' }
                    ] }>
                <Input addonBefore='+7' maxLength={ 10 } placeholder='Номер мобильного телефона' />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col $width={ 300 }>
              <Form.Item name='website' label='Сайт' hasFeedback
                    rules={ [ { message:'Пожалуйста, укажите WEB сайт компании.' } ] }>
                <Input addonBefore='https://' placeholder='Веб сайт организации' />
              </Form.Item>
            </Col>
            <Col $width={ 300 }>
              <Form.Item name='region' label='Город' hasFeedback
                    rules={ [ { message:'Пожалуйста, укажите Ваш город.' } ] }>
                <Input placeholder='Укажите Ваш город' />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item name='description' label='Описание'
                    rules={ [ { message:'Пожалуйста, укажите описание' } ] }>
                <Input.TextArea rows={ 4 } placeholder='Укажите описание' style={{ maxHeight:150 }} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item name='agreement' valuePropName='checked'
                    rules={ [ {
                      validator:( _, value ) =>
                          value ? Promise.resolve() : Promise.reject( new Error( '' ) )
                    } ] }>
                <Checkbox onClick={ () => dispatch( changeStateBtnSendMessege() ) }>
                  Я ознакомился и согласен с <Link to='/privacy'>Политикой персональных данных</Link>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </WorkPlace>
    </Container>
  )
}
import React from 'react'

import { RootState } from 'App/Configure/store'
import { useSelector, useDispatch } from 'react-redux'
import { changeOpenDrawerFeedback } from 'App/Configure/BasicSettingsSlice'

import { Drawer, Space, Button } from 'antd'

import { FullForm } from 'Features/Feedback.group/FullForm'

export const DrFeedback:React.FC = ( ) => {

  const stateBtnSendMessage = useSelector( (state:RootState) => state.BasicSlice.disabledBtnSendMessage )
  const loadingBtnSendMessage = useSelector( (state:RootState) => state.BasicSlice.loadingBtnSendMessage )
  const statusSendMessage = useSelector( (state:RootState) => state.BasicSlice.statusSendMessage )
  const openDrawerFeedback = useSelector( (state:RootState) => state.BasicSlice.openDrawerFeedback )

  const dispatch = useDispatch()

  return(
    <Drawer
        width={ 720 }
        open={ openDrawerFeedback }
        onClose={ () => dispatch( changeOpenDrawerFeedback() ) }
        styles={ { body:{ paddingBottom:80 } } }
        extra={
            <Space>
              { statusSendMessage === 'sending' && ( <div style={ { color:'blue' } }>Отправка...</div> ) }
              { statusSendMessage === 'sent' && ( <div style={ { color:'green' } }>Отправлено</div> ) }
              { statusSendMessage === 'error' && ( <div style={ { color:'red' } }>Сообщение не удалось отправить</div> ) }
                            
              <Button form='full-form' htmlType='reset' type='dashed'>Очистить поля</Button>
              <Button
                  loading={ loadingBtnSendMessage }
                  disabled={ stateBtnSendMessage }
                  form='full-form'                  
                  type="primary"
                  htmlType='submit'>
                Отправить
              </Button>
            </Space>
        }>
      <FullForm />
    </Drawer>
  )
}
import React from 'react'

import { SequenceBoxIconDesc } from 'Shared/Components/SequenceGroup/BoxIconDesc'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { Content } from './Styles'

import { Data } from './data'

import { ReactComponent as IcBrain } from 'Shared/Assets/images/dias.page/industries/IcBrain.svg'
import { ReactComponent as IcElectro } from 'Shared/Assets/images/dias.page/industries/electro.svg'
import { ReactComponent as IcGos } from 'Shared/Assets/images/dias.page/industries/gos.svg'
import { ReactComponent as IcHeart } from 'Shared/Assets/images/dias.page/industries/heart.svg'

const iconSVG = ( value:number ) => {
  switch( value ) {
    case 1:return IcBrain;
    case 2:return IcElectro;
    case 3:return IcGos;
    case 4:return IcHeart;
    default:return 'Error! Image don\'t found!'
  }
}

export const Industries:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 60 }>
      <PrimaryHeader title={ Data.title } />
      <Content>
       <SequenceBoxIconDesc data={ Data.list } fIcon={ iconSVG } />
      </Content>
    </PrimaryContainer>
  )
}

// 
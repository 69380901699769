import styled from "styled-components";
import { Grids } from 'Shared/consts/ConstantsCommon'

export const FlexContainer = styled.div<{ $justify:string, $align:string }>`
    border: ${ Grids ? '1px solid red' : 'none' };
    display:flex;

    justify-content: ${ props =>
      props.$justify === 'space-around'
        ? 'space-around' : props.$justify === 'space-between'
        ? 'space-between' : props.$justify === 'center'
        ? 'center' : 'none' };

    align-items: ${ props =>
      props.$align === 'center'
        ? 'center' : props.$align === 'flex-start'
        ? 'flex-start' : 'flex-end' };
  `
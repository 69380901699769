import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

export const Title = styled.h1`
    border: ${ Grids ? '1px solid orange' : 'none' };

    color:#13087A; font-size:56px;
  `

export const Content = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
  `
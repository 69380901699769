import React from 'react'

import { FloatButton } from 'antd'

import { Navigation } from 'Widgets/Navigation'
import { Header } from 'Widgets/Header'
import { Footer } from 'Widgets/Footer'

import { DiasInfo } from 'Widgets/DiasGroup/DiasInfo'
import { WhyDias } from 'Widgets/DiasGroup/WhyDias'
import { Industries } from 'Widgets/DiasGroup/Industries'
import { RegisterDomesticSoftware } from 'Widgets/DiasGroup/RegisterDomesticSoftware'
import { WhyNow } from 'Widgets/DiasGroup/WhyNow'
import { WhatSystemCanDo } from 'Widgets/DiasGroup/WhatSystemCanDo'
import { EffectsImplementation } from 'Widgets/DiasGroup/EffectsImplementation'

import { DataDiasPage } from 'Shared/consts/DataDiasPage'

import { CSSWrapper } from 'Shared/Styles/CSSTopWrapper'

import { ReactComponent as IcDias } from 'Shared/Assets/images/dias.page/IcDias.svg'

export const DiasPage:React.FC = ( ) => {
  return(
    <React.Fragment>
      <CSSWrapper.Top>
        <Navigation />
        <Header
          header={
            { title: DataDiasPage.header.title, paragraph: DataDiasPage.header.paragraph, btn: DataDiasPage.header.btn }
          }
          Image={ IcDias } documents={ DataDiasPage.header.documents } sizeIcon={ 650 } />
      </CSSWrapper.Top>

      <CSSWrapper.Body>
        <DiasInfo />
        <WhyNow />
        <WhatSystemCanDo />
        <WhyDias />
        <Industries />
        <EffectsImplementation />
        <RegisterDomesticSoftware />
      </CSSWrapper.Body>

      <CSSWrapper.Footer>
        <FloatButton.BackTop />
        <Footer />
      </CSSWrapper.Footer>
    </React.Fragment>
  )
}
import React from 'react'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { ReactComponent as IcBringReportingSingleFormat }
  from 'Shared/Assets/images/dias.page/WhatSystemCanDo/IcBringReportingSingleFormat.svg'
import { ReactComponent as IcPresentComplexDataSimpleWay }
  from 'Shared/Assets/images/dias.page/WhatSystemCanDo/IcPresentComplexDataSimpleWay.svg'
import { ReactComponent as IcTrackIndicatorsMap }
  from 'Shared/Assets/images/dias.page/WhatSystemCanDo/IcTrackIndicatorsMap.svg'
import { ReactComponent as IcMonitorIndicatorsRequiredPeriod }
  from 'Shared/Assets/images/dias.page/WhatSystemCanDo/IcMonitorIndicatorsRequiredPeriod.svg'

import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

const Container = styled.div<{ $reverse?:string }>`
    border: ${ Grids ? '1px solid orange':'none' };
    margin:20px auto;
    display:flex; justify-content:space-around; align-items:center;

    ${ ( props ) => { if( props.$reverse ) { return 'flex-direction:' + props.$reverse } } };

    @media ${ device.maxMobileL } { flex-direction:column; margin-top:15px; }
  `

const Icon = styled.div<{ $width?:number }>`
    border: ${ Grids ? '1px solid darkgreen':'none' };
    height:auto;

    ${ ( props ) => { if( props.$width ) { return 'width:' + props.$width + 'px' } else { return 'width:450px' } } };

    svg{ width:100%; height:auto; }

    @media ${ device.maxMobileL } { width:280px; }
  `

const Content = styled.div`
    border: ${ Grids ? '1px solid darkgreen':'none' };
    width:490px; height:auto;

    background-color:#FFFFFF; border-radius:10px;
    margin:30px 0;
    box-shadow:0 0 10px 0 #CFCFCF;
    transition:.4s;

    h3{ margin:20px; color:#EF7F1A; }
    p{ margin:20px; line-height:23px; }

    &:hover{ box-shadow:0 0 20px 0 #1777F0; }

    @media ${ device.maxMobileL } { width:auto; margin:10px; }
  `

export const WhatSystemCanDo:React.FC = ( ) => {
  return(
    <PrimaryContainer $margin={ 60 }>
      <PrimaryHeader title='Что умеет система?' />
      <Container>
        <Icon $width={ 350 }>
          <IcPresentComplexDataSimpleWay />
        </Icon>

        <Content>
          <h3>Представлять сложные данные в простом виде</h3>
          <p>
            ЦИАС упростит понимание больших объемов данных: вместо 
            кучи «экселек» — пара графиков и диаграмм. Изобразить информацию 
            можно в виде таблиц, схем, гистограмм.
          </p>
        </Content>
      </Container>

      <Container $reverse='row-reverse'>
        <Icon>
          <IcBringReportingSingleFormat />
        </Icon>

        <Content>
          <h3>Приводить отчетность к единому формату</h3>
          <p>
            В крупных или быстрорастущих компаниях сложно стандартизировать 
            отчетность. Пусть отделы присылают документы в привычном виде. 
            Загрузите разрозненные данные в ЦИАС, после чего система 
            покажет картину в целом. 
          </p>
        </Content>
      </Container>

      <Container>
        <Icon>
          <IcTrackIndicatorsMap />
        </Icon>

        <Content>
          <h3>Отслеживать показатели на карте</h3>
          <p>
            Отслеживайте работу подразделений и отдельных филиалов 
            прямо на карте с помощью Геоинформационной подсистемы.
          </p>
        </Content>
      </Container>

      <Container $reverse='row-reverse'>
        <Icon $width={ 350 }>
          <IcMonitorIndicatorsRequiredPeriod />
        </Icon>

        <Content>
          <h3>Контролировать показатели за нужный период</h3>
          <p>
            Быстрая и функциональная визуализация данных позволяет 
            выявить выполнение плана в динамике по дням, месяцам, 
            годам. ЦИАС покажет системные проблемы, сезонные 
            колебания, тенденции и другие метрики в компактном виде.
          </p>
        </Content>
      </Container>
    </PrimaryContainer>
  )
}
export const Data = {
  title:'ГеоИС',
  description:[
    'Подсистема предназначена для автоматизации работ по учету оборудования с нанесением его расположения на цифровую топографическую основу, определения достоверных расстояний существующих и проектируемых ЛЭП в рамках реализации технологических присоединений, создания картографической подосновы электрических сетей с привязкой к геодезическим координатам. ',
  ],
  titleCollapsed:'Возможности ГеоИС',
  list:[
    'Определение областей последствий аварий',
    'Отображение инцидентов и событий на ГеоИС',
    'Онлайн-мониторинг и управление группами',
    'Централизованный контроль за ограничением потребителей при поставке энергоресурсов',
    'Позиционирование карты по адресу',
    'Консолидация данных систем всех ресурсоснабжающих компаний',
    'Поиск по наименованию, уникальному коду объекта',
  ]
}
import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

export const Container = styled.div<{ $color?:string, $fontSize?:number }>`
    border: ${ Grids ? '1px solid green' : 'none' };
    text-align:start;

    h3{
      font-size:50px; position:relative; z-index:1;

      ${
        ( $color ) => {
          if( $color.$color ) {
            return 'color:'+$color.$color;
          } else {
            return 'color:#13087A';
          }
        }
      };

      ${ ( props ) => { if( props.$fontSize ) { return 'font-size:' + props.$fontSize + 'px' } } };
    }

    @media ${ device.maxMobileL } {
      margin:0 10px;
      h3{ font-size:35px; }
    } 
  `
import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

export const Container = styled.div`
    border: ${ Grids ? '1px solid red' : 'none' };
    display:flex; justify-content:space-around; align-items:center;

    margin:30px auto;

    ul{ list-style-type:none; display:flex; }
    li{
      width:250px;
      display:flex; align-items:center;
      font-size:16px; line-height:25px; text-align:center;
      padding:20px; margin:25px;
      border:1px solid #CFCFCF; border-radius:15px;
      background-color:#FFFFFF;
      box-shadow:0 0 8px 0 #CFCFCF;
      transition:.4s;

      &:hover{ box-shadow:0 0 20px 0 #1777F0; }
    }

    @media ${ device.mobileS } {
      ul, li{ display:block; }
    }
  `
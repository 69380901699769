import React from "react";
import { SequenceBlocksContact } from "Shared/Components/SequenceGroup/BlocksContacts";
import { QuickLinks } from "Shared/Components/QuickLinks";
import { Copyright } from "Shared/Components/Copyright";
import { MiniForm } from "Shared/Components/Feedback.group/MiniForm";
import { arrContacts } from "Shared/consts/contacts";
import { FooterDescription } from "./data";

import ITLogo from "Shared/Assets/images/IT_Logo_white.svg";

import {
  LayerContact,
  LayerInfoAndNav,
  LayerCopyright,
  BlockQuickLinks,
  BlockCompanyInfo,
  BlockFooterFeedback,
  Container,
} from "./Styles";

import { FooterQuickLinks } from "Shared/consts/QuickLinks";
import { MiniFormData } from "Shared/consts/DataFeedback";

export const Footer: React.FC = () => {
  return (
    <Container>
      <LayerContact>
        <SequenceBlocksContact contacts={arrContacts} />
      </LayerContact>

      <LayerInfoAndNav>
        <BlockCompanyInfo id="contacts">
          <img
            src={ITLogo}
            alt={"ITLogo"}
            style={{ width: "300px", height: "auto" }}
          />

          <p style={{ marginTop: "20px", color: "#828282" }}>
            {FooterDescription.text}
          </p>
        </BlockCompanyInfo>

        <BlockQuickLinks>
          <QuickLinks data={FooterQuickLinks} />
        </BlockQuickLinks>

        <BlockFooterFeedback>
          <MiniForm data={MiniFormData} />
        </BlockFooterFeedback>
      </LayerInfoAndNav>

      <LayerCopyright>
        <Copyright />
      </LayerCopyright>
    </Container>
  );
};

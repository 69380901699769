import React from 'react'

import { FloatButton } from 'antd'

import { Header } from 'Widgets/Header'
import { Footer } from 'Widgets/Footer'
import { Navigation } from 'Widgets/Navigation'
import { Services } from 'Widgets/IndexGroup/Services'
import { Agile } from 'Widgets/IndexGroup/Agile'

import { DataIndexPage } from 'Shared/consts/DataIndexPage'

import { CSSWrapper } from 'Shared/Styles/CSSTopWrapper'

import { ReactComponent as IHeader } from '../../../Shared/Assets/images/main.page/ImageHeader.svg'

export const IndexPage:React.FC = ( ) => {
  return(
    <React.Fragment>
      <CSSWrapper.Top>
        <Navigation />
        <Header
          header={
            { title: DataIndexPage.header.title, paragraph: DataIndexPage.header.paragraph, btn: DataIndexPage.header.btn }
          }
          Image={ IHeader } documents={ null } sizeIcon={ 650 } />
      </CSSWrapper.Top>

      <CSSWrapper.Body>
        <Services />
        <Agile />
      </CSSWrapper.Body>

      <CSSWrapper.Footer>
        <FloatButton.BackTop />
        <Footer />
      </CSSWrapper.Footer>
    </React.Fragment>
  )
}
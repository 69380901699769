import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

type TPrimaryContainer = {
  $margin?:number,
  $justify?:string,
  $align?:string,
  $height?:string,
}

export const PrimaryContainer = styled.div< TPrimaryContainer >`
    border: ${ Grids ? '1px solid red' : 'none' };
    
    ${ ( props ) => { if( props.$height ) { return 'height:' + props.$height } } };

    ${
      ( $margin ) => {
        if( $margin.$margin ) {
          return 'margin:'+$margin.$margin+'px auto';
        } else {
          return 'margin:0px auto';
        }
      }
    };

    ${ ( props ) => { if( props.$justify || props.$align ) { return 'display:flex' } } };
    ${ ( props ) => { if( props.$align ) { return 'align-items:' + props.$align } } };
    ${ ( props ) => { if( props.$justify ) { return 'justify-content:' + props.$justify } } };

    @media ${ device.maxMobileL } { width:auto; margin:40px 10px; }
    @media ${ device.laptopL } { width:1280px; }
  `
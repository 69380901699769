import React from 'react'

import { Navigation } from 'Widgets/Navigation'
import { Footer } from 'Widgets/Footer'
import { FloatButton } from 'antd'

import { PrimaryContainer } from 'Shared/Styles/PrimaryStylesGroup/Container'
import { PrimaryHeader } from 'Shared/UI/HeaderGroup/PrimaryHeader'

import { CSSWrapper } from 'Shared/Styles/CSSTopWrapper'

import { Data, IPrivacy } from '../Data/data'

import styled from 'styled-components'

const Container = styled.div`
    background-color:#FFFFFF; border-radius:10px;
    margin:15px auto; padding:15px 25px;

    h3{ margin:10px 0; }
    li{ margin:5px 0; }
  `

export const PolicyPage:React.FC = ( ) => {
  return(
    <React.Fragment>
      <CSSWrapper.Top>
        <Navigation />
      </CSSWrapper.Top>

      <CSSWrapper.Body>
        <PrimaryContainer $margin={ 40 }>
          <PrimaryHeader title={ Data.title } size={ 36 } />
          {
            Data.privacy.map( (item:IPrivacy, _) => (
              <Container>
                <h3>{ item.title }</h3>
                <ul>
                  {
                    item.list.map( (item:string, _) => <li>{ item }</li> )
                  }
                </ul>
              </Container>
            ) )
          }
        </PrimaryContainer>
      </CSSWrapper.Body>

      <CSSWrapper.Footer>
        <FloatButton.BackTop />
        <Footer />
      </CSSWrapper.Footer>
    </React.Fragment>
  )
}
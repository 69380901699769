import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

export const Wrapper = styled.div`
    background-color:#13087A;
    padding:50px 0 50px;
  `

export const Content = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
    margin-top:50px;
  `
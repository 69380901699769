import React from 'react'
import { Input, Button, Space } from 'antd'

export const InputWithLoad:React.FC = ( ) => {
  return(
    <Space.Compact
        style={ { width:'100%' } }>
      <Input placeholder='Укажите Ваш Email' />
      <Button type='primary'>Отправить</Button>
    </Space.Compact>
  )
}
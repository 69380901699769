export interface IList {
  title:string, description:string,
}

export const Data = {
  title:'Эффекты от внедрения',
  list:[
    { title:'на 213-380%', description:'увеличение эффективности работоспособности контролеров' },
    { title:'на 25%', description:'повышение производительности' },
    { title:'на 15-40%', description:'уменьшение нагрузки на операторов' },
    { title:'32 млн.руб. экономия', description:'за 1 год на оптимизации передвижения контролеров' },
  ]
}
import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'
import { device } from 'Shared/consts/MediaQueries'

export const Title = styled.h3<{ $textAlign?:string }>`
    border: ${ Grids ? '1px solid orange':'none' };
    color:#EF7F1A; font-size:26px;

    ${ ( props ) => { if( props.$textAlign ) { return 'text-align:' + props.$textAlign } } }
  `

export const Paragraph = styled.p`
    border: ${ Grids ? '1px solid blue':'none' };
    font-size:18px; text-align:justify; padding:30px 0;
    line-height:30px;
  `

export const Icon = styled.div`
    border: ${ Grids ? '1px solid blue':'none' };
    width:350px; height:auto;

    svg{ width:100%; height:auto; }

    @media ${ device.maxMobileL } { width:225px; }
  `

export const Container = styled.div<{ $reverse?:string }>`
    border: ${ Grids ? '1px solid darkorange':'none' };
    display:flex; justify-content:space-around; align-items:flex-start;

    ${ ( props ) => { if( props.$reverse ) { return 'flex-direction:row-reverse' } } };

    @media ${ device.maxMobileL } { flex-direction:column-reverse; align-items:center; }
  `

export const Boxes = styled.div<{ $width?:number }>`
    border: ${ Grids ? '1px solid green':'none' };

    ${ ( props ) => { if( props.$width ) { return 'width:' + props.$width + 'px' } } };

    @media ${ device.maxMobileL } { width:auto; margin:10px; }
  `

